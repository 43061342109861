import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WorkersService } from '@modules/planner/services/workers/workers.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@shared/models/languages';
import { UserService } from '@shared/services/user/user.service';
import { Subject, takeUntil } from 'rxjs';
import languages from 'assets/i18n/language-list.json'
import {TeamsService} from "@modules/planner/services/teams/teams.service";

@Component({
  selector: 'app-workers',
  templateUrl: './workers.component.html',
  styleUrls: ['./workers.component.scss']
})
export class WorkersComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()
  loading = true
  workerStates;
  allStates: Array<any> = Array()
  userLang:any = null;

  skills;
  name;
  public searchFilter: any = '';
  worker = { name: "", qualifications: Array(), projects: Array(), workerteams: Array(), efficiency: 0, efficiencyValue: 0, id: Number, colorInner: "", colorOuter: "", disabled: 0 };
  workers = Array();
  allWorkers = Array();
  newName;
  newEfficiency;
  allWorkerTeams = Array();
  personFirstName;
  personLastName;
  regions;
  subcontractor = false;
  email;
  postCode;
  address;
  efficiencyFactor = 1.0;
  username;
  password;
  phoneNumber;
  workingHours = Array();
  projectId;
  allSkills = Array();
  skillsToAdd = Array();
  confirmationForResettingValues = true;
  subcontractorValue = 0;
  selectedSkill;
  selectTextStyle = { ['color']: '#707591' }
  newSkill;
  realClose = true;
  addedSkills = Array();
  showProfessionalInformation = false;
  postalDistrict;
  imageProfessionalInformation = "assets/icons/expand_more_black_24dp.svg";
  isWindowTooSmall = false;

  interfaceLanguages: Array<Language> = []
  workerLanguage: string = "de"

  constructor(
    private router: Router,
    private workersService: WorkersService,
    private userService: UserService,
    private translateService: TranslateService,
    private teamsService: TeamsService,
  ) { }

  ngOnInit() {
    this.userLang = localStorage.getItem('userlanguage');
    this.getProjectId();

    let firstModal = document.getElementById('workerModal')
    firstModal?.addEventListener('hidden.bs.modal', (event) => {
      if (this.realClose == true) this.resetVariables()
    })

    this.setEventListenersForSecondModal()
    if (window.innerWidth < 1000) this.isWindowTooSmall = true;
    this.getTranslations()
  }


  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 1000) this.isWindowTooSmall = true;
    else this.isWindowTooSmall = false;
  }

  /**
   * Set names according to translation of weekdays for working hours table.
   * Set languages to correct display name.
   * @author Jesse Lindholm
   *
   * 16.11.2022
   * Updated languages to use JSON-languages from JSON-file.
   */
  getTranslations() {
    this.translateService.get('basic.weekDays.monday').subscribe(
      () => {
        languages.languages.forEach(language => {
          if (this.translateService.instant(`languages.${language}`) !== `languages.${language}`) this.interfaceLanguages.push({ databaseLanguage: language, displayName: this.translateService.instant(`languages.${language}`) })
          else this.interfaceLanguages.push({ databaseLanguage: language, displayName: `No translation (${language})` })
        })
      }
    )
  }

  /**
   * Get workers from database to make a base for page
   * Go through values of data to put correct data to correct variables
   * Finally make a single new worker for every data value in database
   */
  getWorkers() {
    this.workersService.getWorkersWithProjectId(this.projectId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          for (let key in data) {
            let value = data[key]
            this.worker = { name: "", qualifications: Array(), projects: Array(), workerteams: Array(), efficiency: 0, efficiencyValue: 0, id: Number, colorInner: "", colorOuter: "", disabled: 0 };
            this.worker.name = value.firstname + " " + value.lastname
            this.worker.disabled = value.disabled
            this.setSkills(value.qualifications)
            if (value.efficiency == null) {
              this.worker.efficiency = 0
              this.worker.efficiencyValue = 0.0
            } else {
              this.worker.efficiency = (value.efficiency * 100)
              this.worker.efficiencyValue = value.efficiency
            }
            this.setProjects(value.projects)
            this.setWorkerteams(value.workerteams)
            this.worker.id = value.id
            if (value.efficiency >= 1.0) {
              this.worker.colorInner = '#10A231'
              this.worker.colorOuter = '#05791F'
              this.worker.efficiency = (value.efficiency - 1) * 100
            } else {
              this.worker.colorOuter = '#FF0000'
              this.worker.colorInner = '#ffcccb'
            }
            this.workers.push(this.worker)
          }
          this.allWorkers = this.workers
          this.skills = this.workers;
          this.selectedSkill = undefined;
          this.selectTextStyle = { ['color']: '#707591' }
          this.loading = false
          this.sortSkills()
        }
      )
  }

  /**
   * Go through projects and get the values and save the values to current worker
   * @param projectsJSON projects from database
   */
  setProjects(projectsJSON) {
    let projects = Array()
    let id = 0;

    for (let i = 0; i < Object.keys(projectsJSON).length; i++) {
      projects.push(Object.values(projectsJSON)[i])
    }
    // Set workerteams to current worker
    this.worker.projects = projects;
  }

  /**
   * Go through workerteams and get the values and save the values to current worker
   * @param workerteamsJSON workerteams from database
   */
  setWorkerteams(workerteamsJSON) {
    let workerteams = Array()
    let id = 0;
    for (let i = 0; i < Object.keys(workerteamsJSON).length; i++) {
      workerteams.push(Object.values(workerteamsJSON)[i])
    }
    // Set workerteams to current worker
    this.worker.workerteams = workerteams;

    // Loop through workerteams to check if they exist in allWorkerteams variable
    // If does not exist add workerteam to allWorkerTeams variable for further usage
    for (let j = 0; j < workerteams.length; j++) {
      let isInArray = false;
      for (let x = 0; x < this.allWorkerTeams.length; x++) {
        if (workerteams[j] == this.allWorkerTeams[x]) isInArray = true;
      }
      if (isInArray == false) {
        let jsonVariable = {}
        jsonVariable[id] = workerteams[j]
        this.allWorkerTeams.push(jsonVariable)
        id++;
      }
    }
  }

  /**
   * Set skills to allSkills variable for further usage
   * Also set skills for worker qualifications
   * @param qualifications skills from database
   */
  setSkills(qualifications) {
    let skills = Array()
    if (qualifications != null) {
      try {
        let parsedQualifications = JSON.parse(qualifications)
          skills = this.allStates.filter(skill => parsedQualifications.includes(skill.id));
      } catch (e) {
        // console.log(e)
      }
    }
    // Set local variable skills to current worker
    this.worker.qualifications = skills;
  }

  /**
   * Navigate to correct worker detail page
   */
  goToWorkerDetail(event, id) {
    event.stopPropagation();
    let uri = "planner/worker-detail/" + id;
    this.router.navigate([uri])
    return false;
  }

  /**
   * Called when clicked 'save' on modal for making a new worker
   * Push days to workingHours
   * Close modal
   * Show user a sweetalert pop up for giving user username and password
   * Make api calls if pop up has been clicked with save and not if has been clicked with cancel
   * Reset variables
   *
   * 31.10.2022
   * Added language to fullworker api-call
   * Save button disabled after submit to prevent multiple clicks
   * @author Jesse Lindholm
   */
  addNewWorker() {

    // Disable save button briefly to prevent adding worker more than once
    let saveButton = <HTMLButtonElement>document.getElementById('modalSaveButton')
    saveButton.disabled = true

    // Close modal when this is run by clicking modal close
    this.confirmationForResettingValues = true;
    let button = document.getElementById('modalClose')
    button?.click();

    if (this.efficiencyFactor == null) this.efficiencyFactor = 1.0;
    if (this.workerLanguage === "") this.workerLanguage = 'en'
    this.userService.createWorker(
      this.projectId,
      this.personFirstName,
      this.personLastName,
      this.email, 
      this.username,
      this.password,
      this.workerLanguage,
      this.skillsToAdd,
      this.efficiencyFactor,
      this.subcontractor
    )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        () => {
          this.workers = Array();
          this.getWorkers();
          saveButton.disabled = false
        }
      )
    
      this.resetVariables();


  }

  /**
   * Reset variables after changes have been cancelled or saved
   */
  resetVariables() {
    if (this.confirmationForResettingValues == true) {
      this.personFirstName = null;
      this.personLastName = null;
      this.username = null;
      this.email = null
      this.postalDistrict = null;
      this.regions = null;
      this.workingHours = Array();
      this.phoneNumber = null;
      this.address = null;
      this.postCode = null;
      this.efficiencyFactor = 1.0;
      this.subcontractor = false;
      this.skillsToAdd = Array();
      this.subcontractorValue = 1;
      this.showProfessionalInformation = false;
      if (this.addedSkills != null) {
        for (let x = 0; x < this.addedSkills.length; x++) {
          for (let z = 0; z < this.allSkills.length; z++) {
            if (this.allSkills[z].name == this.addedSkills[x] && this.allSkills[z].checked == false) {
              this.allSkills.splice(z, 1);
            }
          }
        }
      }
      this.addedSkills = Array();
      for (let i = 0; i < this.allSkills.length; i++) {
        this.allSkills[i].checked = false;
      }

    } else {
      this.confirmationForResettingValues = true;
    }
    // Reset new variable workerLanguage
    this.workerLanguage = ""
    this.uncheckAllStates(this.allStates);
  }


  uncheckAllStates(states: any[]): void {
    states.forEach(state => {
      if ('checked' in state) {
        state.checked = false;
      }
    });
  }

  /**
   * Get project id. Ran when opening page so we have access to it after opening page immediatly
   */
  getProjectId() {
    this.userService.getUserInfo()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        data => {
          this.projectId = data.current_project;
            this.userLang = data.language
          this.getStatuses();
        }
      );
  }


    getStatuses() {
        this.teamsService.getStatuses()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                data => {
                    this.workerStates = data

                    for (let i = 0; i < this.workerStates.length; i++) {
                        let definition
                        let defjson = JSON.parse(this.workerStates[i].definition) || {}
                        if(this.userLang) {
                            definition = defjson[this.userLang] ||
                                defjson['en'] ||
                                undefined;
                        } else {
                            definition = defjson['en'] || undefined;
                        }
                        this.workerStates[i].definition = definition
                        if (this.workerStates[i].state !== 1) this.allStates.push({id: this.workerStates[i].id, definition: definition})
                    }
                    this.getWorkers()
                }
            )
    }

  /**
   * Add skill to selected skills list in UI in new worker addition window
   * @param index id of clicked row
   */
  addSkill(index) {
    let boolean = false
    for (let i = 0; i < this.skillsToAdd.length; i++) {
      if (this.skillsToAdd[i].definition == this.allStates[index].definition) {
        boolean = true;
        this.skillsToAdd.splice(i, 1);
      }
    }
    if (boolean == false) this.skillsToAdd.push(this.allStates[index])
  }

  setSubcontractorValue() {
    if (this.subcontractor == false) {
      this.subcontractorValue = 1;
    }
    if (this.subcontractor == true) {
      this.subcontractorValue = 0;
    }
  }

    filterStateChanged(value) {
        if (value != 'undefined') {
            value = parseInt(value)
            let newList = Array()
            for (let i = 0; i < this.allWorkers.length; i++) {
                // if (this.allWorkers[i].qualifications == value) newList.push(this.allWorkers[i])
                newList = this.allWorkers.filter(worker => worker.qualifications.some(skill => skill.id === value));
            }
            this.workers = newList;
        }
        else {
            this.workers = this.allWorkers;
        }
    }

  createNewSkill() {

    this.allSkills.push({ name: this.newSkill, checked: false })
    this.addedSkills.push(this.newSkill)

    let button = document.getElementById('modalCloseNewSkill')
    button?.click();
    this.newSkill = null;
  }

  resetNewSkill() {
    this.newSkill = null;
  }

  setEventListenersForSecondModal() {
    let secondModal = document.getElementById('createNewSkillModal')
    if (secondModal?.getAttribute('listener') !== 'true') {
      secondModal?.addEventListener('hidden.bs.modal', (event) => {
        secondModal?.setAttribute('listener', 'true');
        this.resetNewSkill()
        this.realClose = true;
        let workerButton = document.getElementById('newWorker')
        workerButton?.click();

      });
      secondModal?.addEventListener('show.bs.modal', (event) => {
        secondModal?.setAttribute('listener', 'true');
        this.realClose = false;
      })
    }
  }

  toggleProfessionalInformation() {
    if (this.showProfessionalInformation == false) {
      this.showProfessionalInformation = true;
      this.imageProfessionalInformation = "assets/icons/expand_less_black_24dp.svg";
    }
    else {
      this.showProfessionalInformation = false;
      this.imageProfessionalInformation = "assets/icons/expand_more_black_24dp.svg";
    }

  }

  /**
   * Change workers language to parameters value
   * @param language chosen language to new worker
   */
  languageChange(language: string) {
    this.workerLanguage = language
  }

  /**
   * Sort skills
   * @param order optional, 1 for reversed sort
   */
  sortSkills(order: number = 0) {
    if (order == 1) {
      // desc
      this.allSkills.sort((a, b) => b.name.localeCompare(a.name))
    } else {
      //asc
      this.allSkills.sort((a, b) => a.name.localeCompare(b.name))
    }
  }
}



