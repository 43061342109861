import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { AuthService } from './auth.service';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { UnauthorizedInterceptor } from '@core/interceptors/unauthorized.interceptor';
import { TranslateModule } from '@ngx-translate/core';
import { RegisterComponent } from './components/register/register.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';

@NgModule({
    declarations: [LoginComponent, RegisterComponent, ForgotPasswordComponent],
    providers: [
      AuthGuard,
      AuthService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: UnauthorizedInterceptor,
        multi: true
      }
    ],
    imports: [
      CommonModule,
      RouterModule,
      HttpClientModule,
      ReactiveFormsModule,
      TranslateModule,
      FormsModule
    ]
  })
  export class AuthModule { }
