<div class="page-container">
    <div class="page-content">
        <div *ngIf="!cleanupCalendar">
            <app-weekly-planning-teams
            [teamId]="teamId"
            ></app-weekly-planning-teams>
        </div>


        <div class="card">
            <div class="card-content">
                <div class="card-body">
                    <div *ngIf="saveSpinner" class="justify-content-center d-flex">
                        <load-spinner></load-spinner>
                    </div>
                    <div *ngIf="!saveSpinner">
                        <div *ngIf="cleanupCalendar">
                            <button type="button" class="btn btn-secondary" (click)="backToDashboard()">
                                <img src="assets/icons/chevron_left_gray_24dp.svg">
                                <span class="position-fix-back-to-teams">{{'basic.backToTeams' | translate }}</span>
                            </button>
                        </div>
                        <div class="ms-3 mt-2">
                            <h4 *ngIf="mode == 'create'">{{'calendar.createCalendar' | translate }}</h4>
                            <h4 *ngIf="mode == 'update'">{{'calendar.editCalendar' | translate }}</h4>
                            <form ngNativeValidate (ngSubmit)="saveCalendar()">
                                <div class="justify-content-center d-flex red-text" *ngIf="msaWrong || timeWrong">{{'weeklyPlanningDetails.msaNotSelectedOrDatesWrong' | translate }}</div>
                                <div class="row mb-4">
                                    <div *ngIf="mode == 'create'" class="col-2">
                                        <div class="calendar-name mt-2">{{'weeklyPlanningDetails.calendarName' | translate }}</div>
                                        <input type="text" class="form-control max-content mt-2" [(ngModel)]="calendarName" required [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div *ngIf="mode == 'update'" class="col-2">
                                        <div class="calendar-name mt-2">{{'weeklyPlanningDetails.calendarName' | translate }}</div>
                                        <div>{{calendarName}}</div>
                                    </div>
                                    <div class="col-6" *ngIf="cleanupCalendar">
                                        <div class="row">
                                            <div class="col-4">
                                                <load-spinner *ngIf="msaSpinner"></load-spinner>
                                                <div *ngIf="!msaSpinner">
                                                    <div class="calendar-name mt-2">{{'weeklyPlanningDetails.selectMsa' | translate }}</div>
                                                    <div ngbDropdown *ngIf="msa">
                                                        <button class="btn btn-secondary mt-2" type="button" id="dropdownMSAButton" (focusout)="toggleExpand()" (click)="toggleExpand()" ngbDropdownToggle>
                                                            <span class="ms-2 black-text">{{msa.name}}<img class="image-expand" [src]="expand"></span>
                                                        </button>
                                                        <div ngbDropdownMenu aria-labelledby="dropdownMSAButton">
                                                            <div *ngFor="let msa of msas">
                                                                <button ngbDropdownItem type="button" (click)="selectMsa(msa.id)">{{msa.name}}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-8" *ngIf="mode == 'create'">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="calendar-name mt-2">{{'weeklyPlanningDetails.calendarStarts' | translate }}</div>
                                                        <input type="date" class="form-control mt-2" [(ngModel)]="calendarStartCleanup" required [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="calendar-name mt-2 ">{{'weeklyPlanningDetails.calendarEnds' | translate }}</div>
                                                        <input type="date" class="form-control mt-2" [(ngModel)]="calendarEndCleanup" required [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4 d-inline" *ngIf="!cleanupCalendar">
                                        <div class="text-center">
                                            <img class="mr-5" src="assets/icons/house_gray_24dp.svg">
                                            <img src="assets/icons/today_gray_24dp.svg">
                                        </div>
                                        <div class="d-flex justify-content-center">
                                            <img class="chevron p-2" src="assets/icons/chevron_left_gray_24dp.svg" (click)="moveWeek('back')">
                                            <div class="text-center px-3">
                                                <span class="gray-text">{{'basic.week' | translate}} {{weekNumber}}</span>
                                                <br>
                                                <span>{{firstDayOfWeek | date: 'dd.MM.yyyy'}} - {{lastDayOfWeek | date: 'dd.MM.yyyy'}}</span>

                                            </div>
                                            <img class="chevron p-2" src="assets/icons/chevron_right_gray_24dp.svg" (click)="moveWeek('forward')">
                                        </div>
                                    </div>
                                    <div class="col-2" *ngIf="cleanupCalendarSpinner && !cleanupCalendar">
                                        <load-spinner></load-spinner>
                                    </div>
                                    <div class="col-2" *ngIf=" !cleanupCalendarSpinner && !cleanupCalendar">
                                        <div class="calendar-name">{{'weeklyPlanningDetails.selectCleanupCalendar' | translate }}</div>
                                                <div ngbDropdown >
                                                    <button class="btn btn-secondary p-2 mt-2" type="button" id="dropdownCleanupButton" (focusout)="toggleExpand2()" (click)="toggleExpand2()" ngbDropdownToggle>
                                                        <span *ngIf="selectedCleanupCalendar != null" class="ms-2 black-text">{{selectedCleanupCalendar.name}}<img class="image-expand" [src]="expand2"></span>
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownCleanupButton">
                                                        <div *ngFor="let calendar of cleanupCalendars">
                                                            <button ngbDropdownItem type="button" (click)="selectCleanupCalendar(calendar.id)">{{calendar.name}}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                    </div>
                                    <div class="col-4 text-right">
                                        <button type="submit" class="btn btn-primary">{{saveCalendarText}}</button>
                                        <button type="button" class="btn btn-secondary ms-3">{{'basic.cancel' | translate }}</button>
                                    </div>
                                </div>
                            </form>
                            <div class="mb-3 row">
                                <div [ngClass]="cleanupCalendar ? 'col-4' : 'col-5'">
                                    <button type="button" *ngIf="!toggleSlots" class="btn btn-secondary link-button" (click)="generateSlotsToggle('open')">
                                        <img src="assets/icons/today_gray_24dp.svg">
                                        <span class="ms-2 position-fix-link-button">{{'weeklyPlanningDetails.generateSlots' | translate }}</span>
                                    </button>
                                    <button type="button" *ngIf="!toggleSlots && toggleCopySlots" [disabled]="copyDisabled"  data-bs-toggle="modal" data-bs-target="#copyWeekModal" [ngClass]="cleanupCalendar ? 'mx-0' : 'mx-2'" class="btn btn-secondary link-button">
                                        <img *ngIf="!copyDisabled" src="assets/icons/file_copy_gray_24dp.svg">
                                        <img *ngIf="copyDisabled" src="assets/icons/file_copy_gray_disabled_24dp.svg">
                                        <span class="ms-2 position-fix-link-button" *ngIf="!copyDisabled">{{'weeklyPlanningDetails.saveThisWeekAsTemplate' | translate }}</span>
                                        <span class="ms-2 position-fix-link-button text-gray" *ngIf="copyDisabled">{{'weeklyPlanningDetails.saveThisWeekAsTemplate' | translate }}</span>
                                    </button>
                                    <button type="button" *ngIf="!toggleSlots && toggleCopySlots" (click)="resetCalendar('reset')" class="btn btn-secondary link-button">
                                        <img src="assets/icons/event_busy_gray_24dp.svg">
                                        <span class="ms-2 position-fix-link-button">{{'weeklyPlanningDetails.resetWeek' | translate }}</span>
                                    </button>
                                </div>
                                <div class="col-4 d-inline" *ngIf="cleanupCalendar">
                                    <div class="text-center">
                                        <img class="mr-5" src="assets/icons/house_gray_24dp.svg">
                                        <img src="assets/icons/today_gray_24dp.svg">
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <img class="chevron p-2" src="assets/icons/chevron_left_gray_24dp.svg" (click)="moveWeek('back')">
                                        <div class="text-center px-3">
                                            <span class="gray-text">{{'basic.week' | translate}} {{weekNumber}}</span>
                                            <br>
                                            <span>{{firstDayOfWeek | date: 'dd.MM.yyyy'}} - {{lastDayOfWeek | date: 'dd.MM.yyyy'}}</span>

                                        </div>
                                        <img class="chevron p-2" src="assets/icons/chevron_right_gray_24dp.svg" (click)="moveWeek('forward')">
                                    </div>
                                </div>
                                <div class="col-4" *ngIf="!cleanupCalendar && msaSpinner">
                                    <load-spinner></load-spinner>
                                </div>
                                <div class="col-2">
                                    <div class="calendar-name mt-2">{{'weeklyPlanningDetails.calendarSlotFill' | translate }}</div>
                                    <div class="percent-input">
                                        <input type="text" min="0" max="{{defaultmax}}" placeholder="{{defaultmax}}%" class="form-control max-content mt-2" [(ngModel)]="calendarSlotFill">
                                        <div class="red-text small-text" [hidden]="calendarSlotFill <= defaultmax">{{'weeklyPlanningDetails.overdefault' | translate }} {{defaultmax}}</div>
                                    </div>
                                </div>
                                <div class="col-3" *ngIf="!cleanupCalendar && !msaSpinner">
                                    <div class="calendar-name mt-2">{{'weeklyPlanningDetails.selectMsa' | translate }}</div>
                                    <div ngbDropdown *ngIf="msa">
                                        <button class="btn btn-secondary p-2 mt-2" type="button" id="dropdownMSAButton2" (focusout)="toggleExpand()" (click)="toggleExpand()" ngbDropdownToggle>
                                            <span class="ms-2 black-text">{{msa.name}}<img class="image-expand" [src]="expand"></span>
                                        </button>
                                        <div ngbDropdownMenu aria-labelledby="dropdownMSAButton2">
                                            <div *ngFor="let msa of msas">
                                                <button ngbDropdownItem type="button" (click)="selectMsa(msa.id)">{{msa.name}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div ngbDropdown class=" d-flex justify-content-end" [ngClass]="cleanupCalendar ? 'col-4' : 'col-2'">
                                    <button class="btn btn-secondary link-button" *ngIf="!toggleSlots" id="dropdownMenuButton" ngbDropdownToggle>
                                        <img src="assets/icons/file_copy_gray_24dp.svg">
                                    <span class=" position-fix-link-button">{{'weeklyPlanningDetails.templates' | translate }}</span>
                                    </button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                                        <div *ngFor="let template of templatesArray">
                                            <button ngbDropdownItem (click)="applyTemplate(template.id)">{{template.name}}</button>
                                        </div>
                                        <div class="small-text px-2" *ngIf="templatesArray.length == 0">{{'weeklyPlanningDetails.noTemplatesSaved' | translate }}</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="toggleSlots" class="background-light p-3">
                                <div class="fw-bold">{{'weeklyPlanningDetails.generateSlots' | translate }}</div>
                                <div *ngFor="let slot of slots; let i = index">
                                    <div *ngIf="i > 0" class="mt-2"></div>
                                    <div class="fw-bold small-text">{{slot.name}}</div>
                                    <div class="row mt-2">
                                        <div class="col-3 col-xxl-2">
                                            <div class="gray-text small-text">{{'weeklyPlanningDetails.amountOfSlots' | translate }}</div>
                                            <input type="number" class="form-control width-form" [(ngModel)]="slot.selectedSlots">
                                        </div>
                                        <div class="col-3 col-xxl-2">
                                            <div class="gray-text small-text">{{'weeklyPlanningDetails.durationOfSlot' | translate}}</div>
                                            <div class="d-flex">
                                            <input type="number" class="form-control width-form" [(ngModel)]="slot.slotDuration">
                                            <span class="ms-2 mt-2">{{'weeklyPlanningDetails.hours' | translate }}</span>
                                            </div>
                                        </div>
                                        <div class="col-4 col-xxl-6">
                                            <div class="gray-text small-text">{{'weeklyPlanningDetails.firstSlotStartsAt' | translate }}</div>
                                            <input type="time" class="form-control max-content" [(ngModel)]="slot.firstSlotStartTime">
                                        </div>
                                        <div class="col-2">
                                            <button type="button" class="btn btn-secondary" (click)="deleteSlot(i, slot)">{{'basic.delete' | translate }}</button>
                                        </div>
                                    </div>
                                    <div class="gray-text mt-3 mb-4 small-text">{{'weeklyPlanningDetails.days' | translate }}</div>
                                    <div class="d-flex">
                                        <div *ngFor="let day of slot.days; let x = index">
                                            <div class="me-2 width-40">
                                                <label class="container position-slot-checkbox"  [style.cursor]="day.cursor" >
                                                    <div class=" d-flex justify-content-center text-slot" *ngIf="!day.disabled">{{day.name}}</div>
                                                    <div class=" d-flex justify-content-center text-slot text-gray" *ngIf="day.disabled">{{day.name}}</div>
                                                    <input type="checkbox" [disabled]="day.disabled" [(ngModel)]="day.checked" (click)="clickedCheckbox(x, slot)">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-5">
                                    <button type="button" class="btn btn-primary py-0" (click)="addNewSlot()">
                                        <span class="fs-4">+</span>
                                        <span class="position-fix-plus ms-2">{{'weeklyPlanningDetails.addNewSlotType' | translate }}</span>
                                    </button>
                                </div>
                                <div class="mt-4">
                                    <button type="button" class="btn btn-secondary me-2" (click)="generateSlotsToggle('close')">{{'basic.cancel' | translate }}</button>
                                    <button type="button" class="btn btn-primary" (click)="applyToCalendar()">{{'weeklyPlanningDetails.applyToCalendar' | translate }}</button>
                                </div>
                            </div>
                            <div class="blue-text small-text mt-1" *ngIf="copiedWeek">{{'weeklyPlanningDetails.thisWeekIsCopied' | translate }}</div>
                            <div *ngFor="let day of days" >
                                <div *ngIf="day.boolean == true" class="d-flex justify-content-center">
                                    <div class="display-flex w-100 justify-content-between mt-5">
                                        <div *ngFor="let item of day.array; let j = index" [style.width]="widthSlot[j].width" >
                                            <div class="d-flex justify-content-center fw-bold">{{item.name}} {{item.date | date: 'dd.MM'}}</div>
                                            <div *ngIf="item.slot.length == 0">
                                            </div>
                                            <div *ngIf="item.slot.length > 0">
                                                <div *ngFor="let slot of item.slot; let i = index">
                                                    <div class="border border-gray" [ngStyle]="slot.styling">
                                                        <div class="me-2 margin-top-slot height-15">
                                                            <label class="container height-10" >
                                                                <span class="checkmark-text-disabled" *ngIf="!slot.checked">{{'basic.disabled' | translate }}</span>
                                                                <span class="checkmark-text-disabled white-text" *ngIf="slot.checked">{{'basic.disabled' | translate }}</span>
                                                                <input type="checkbox" [(ngModel)]="slot.disabled"  (click)="disabledClicked(slot)" checked="checked">
                                                                <span class="checkmark2" [style.border]="slot.border" [style.background-color]="slot.background"></span>
                                                                <a class="description float-end" *ngIf="slot.disabled && !slot.description" (click)="saveSlotToDescription(slot)" data-bs-toggle="modal" data-bs-target="#descriptionCreateModal"><img src="assets/icons/description_white_24dp.svg"></a>
                                                                <a class="description float-end" *ngIf="slot.disabled && slot.description" (click)="toggleSlotDescription(slot, 'open')" data-bs-toggle="modal" data-bs-target="#descriptionShowModal"><img src="assets/icons/description_white_24dp.svg"></a>
                                                            </label>

                                                        </div>
                                                        <div class="display-flex justify-content-center mb-2">
                                                            <input type="time" class="input-time" [(ngModel)]="slot.timeStart">
                                                            <span *ngIf="!slot.disabled">-</span>
                                                            <span class="white-text" *ngIf="slot.disabled">-</span>
                                                            <input type="time" class="input-time me-2 " [(ngModel)]="slot.timeEnd">
                                                        </div>
                                                        <div class="display-flex justify-content-center mb-2">
                                                            <div class="calendar-name mt-2">{{'weeklyPlanningDetails.calendarSlotFill_1' | translate }}</div>
                                                            <div class="mt-2 mx-2">
                                                                <input type="text" min="0" max="{{defaultmax}}" placeholder="{{calendarSlotFill}}%" class="form-control max-content " [(ngModel)]="slot.slot_max">
                                                                <div class="red-text small-text" [hidden]="slot.slot_max <= defaultmax">{{'weeklyPlanningDetails.overdefault' | translate }} {{defaultmax}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex justify-content-center">
                                                        <button type="button" class="btn btn-secondary delete-slot mb-2" (click)="deleteSlotFromSlots(slot)">
                                                            <img src="assets/icons/close_gray_24dp.svg">
                                                            <span class="gray-text">{{'weeklyPlanningDetails.deleteSlot' | translate }}</span>
                                                        </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-center mt-2">
                                                <button type="button" class="btn btn-secondary add-slot-button mt-1 pb-0" (click)="showAddSlot(j)">
                                                    <span class="fs-4">+</span>
                                                    <span class="ms-2 position-fix-plus">{{'weeklyPlanningDetails.addSlot' | translate }}</span>
                                                </button>
                                            </div>
                                            <div class="arrow-up" *ngIf="day.array[j].showAddSlot"></div>
                                            <div class="border border-gray p-2" [style.z-index]="j" *ngIf="day.array[j].showAddSlot">
                                                <span class="fw-bold ms-1 mt-1">{{'weeklyPlanningDetails.addSlot' | translate }}</span>
                                                <a (click)="closeAddSlot(j)"><img class="float-end" src="assets/icons/close_gray_24dp.svg"></a>
                                                <div class="mt-2">
                                                    <div class="d-flex small-text gray-text">
                                                        <div class="ms-1 me-3">{{'weeklyPlanningDetails.slotStartsAt' | translate }}</div>
                                                        <div>{{'weeklyPlanningDetails.durationOfSlot' | translate }}</div>
                                                    </div>
                                                    <form ngNativeValidate (ngSubmit)="saveAddSlot(j)">
                                                        <div class="d-flex mb-2">
                                                            <input type="time" class="input-add-slot-time ms-1 me-4" required [(ngModel)]="slotAddSlot[j].start" [ngModelOptions]="{standalone: true}">
                                                            <input type="number" min="1" class="input-add-slot-number me-1" required [(ngModel)]="slotAddSlot[j].duration" [ngModelOptions]="{standalone: true}">
                                                            <div class="small-text gray-text hours">{{'weeklyPlanningDetails.hours' | translate }}</div>
                                                        </div>
                                                        <button type="submit" class="ms-2 btn btn-primary width-button-add-slot">{{'weeklyPlanningDetails.saveNewSlot' | translate }}</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- Modal create -->
        <div class="modal fade" id="descriptionCreateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <h3>{{'weeklyPlanningDetails.modal.enterDescription' | translate }}</h3>
                        <form ngNativeValidate (ngSubmit)="addDescription('main')">
                            <textarea [(ngModel)]="currentDescription" [ngModelOptions]="{standalone: true}" required></textarea>
                            <div class="mt-2">
                                <button type="submit" class="btn btn-primary">{{'basic.save' | translate }}</button>
                                <button type="button" class="btn btn-secondary" (click)="modalCancel('main')" data-bs-dismiss="modal" id="modalClose">{{'basic.cancel' | translate }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal create end -->
        <!-- Modal show -->
        <div class="modal fade" id="descriptionShowModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <h3>{{'weeklyPlanningDetails.modal.slotsDescription' | translate }}</h3>
                        <div class="mb-2 ms-2" *ngIf="!editDescription">{{currentDescription}}</div>
                        <form ngNativeValidate (ngSubmit)="addDescription('edit')" *ngIf="editDescription">
                            <textarea [(ngModel)]="currentDescription" [ngModelOptions]="{standalone: true}" required></textarea>
                            <div>
                                <button type="submit" class="btn btn-primary">{{'basic.save' | translate }}</button>
                                <button type="button" class="btn btn-secondary" (click)="modalCancel('edit')" data-bs-dismiss="modal" id="modalCloseEdit">{{'basic.cancel' | translate }}</button>
                            </div>
                        </form>
                        <button type="button" *ngIf="!editDescription" class="btn btn-primary me-2"  (click)="toggleEditDescription()">{{'basic.edit' | translate }}</button>
                        <button type="button" *ngIf="!editDescription" class="btn btn-secondary" data-bs-dismiss="modal" (click)="toggleSlotDescription(null, 'close')">{{'basic.close' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal show end -->
        <!-- Modal show -->
        <div class="modal fade" id="copyWeekModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <h3 class="fw-bold">{{'basic.saveThisWeekAsTemplate' | translate }}</h3>
                        <div class="gray-text mb-2">{{'weeklyPlanningDetails.modal.templateName' | translate }}</div>
                        <form ngNativeValidate (ngSubmit)="copyThisWeek()">
                            <input type="text" class="form-control max-content" required [(ngModel)]="templateName" [ngModelOptions]="{standalone: true}">
                            <div class="d-flex mt-5">
                                <label class="container width-fit-content position-whole-week">
                                    <span class="checkmark-text">{{'weeklyPlanningDetails.modal.wholeWeek' | translate }}</span>
                                    <input type="checkbox" (click)="toggleAllDaysCopy()" [(ngModel)]="copyWholeWeek" [ngModelOptions]="{standalone: true}" checked="checked">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container width-fit-content">
                                    <span class="checkmark-text">{{'weeklyPlanningDetails.modal.disabledSlots' | translate }}</span>
                                    <input type="checkbox" [(ngModel)]="disabledSlotsCopy" [ngModelOptions]="{standalone: true}">
                                    <span class="checkmark"></span>
                                </label>
                            </div>

                            <div *ngFor="let item of copy">
                                <label class="container ms-4 width-fit-content">
                                    <span class="checkmark-text">{{item.name}}</span>
                                    <input type="checkbox" [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <button type="button" class="btn btn-secondary me-2" id="copyCancel" data-bs-dismiss="modal">{{'basic.cancel' | translate }}</button>
                            <button type="submit" class="btn btn-primary">{{'weeklyPlanningDetails.modal.copy' | translate }}</button>
                        </form>

                    </div>
                </div>
            </div>
        </div>
        <!-- Modal show end -->
    </div>
</div>
