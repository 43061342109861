import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { computeStyles } from '@popperjs/core';
import { ToastService } from '@shared/services/toast/toast.service';
import { catchError, map, Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FieldReportingService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
    ) { }

  // TODO: Change handleError 'string' to better responses
  getFieldReporting(type: number = 1) {
    let params = new HttpParams()
    params = params.set('type', type)
    return this.http.get(`${baseUrl}/api/fieldreporting`, {params: params})
    .pipe(
      map((data) => {
        return data['message'];
        
      }),
      catchError(
        this.handleError(this.translateService.instant('services.gettingWorkordersFailed'), [])
      )
    )
  }

  getDoneWorkOrdersForTeam(teamId: number, type: number): Observable<any> {
    let params = new HttpParams()
    params = params.set('type', type)
    const url = `${baseUrl}/api/doneworkordersforteam/${teamId}`
    return this.http.get(url, {params: params}).pipe(
      map((data) => {
        return data['message'];
      }),
      catchError(
        this.handleError(this.translateService.instant('services.gettingWorkordersFailed'), [])
      )
    );
  }

  reportWorkorder(id, data, formType = "create") {
      if(formType == "edit") {
          return this.http.post(`${baseUrl}/api/reportAgain/${id}`, data)
              .pipe(
                  map((data) => {
                      this.toastService.sendToast(true, this.translateService.instant('services.reportSent'))
                      return data['message']
                  }),
                  catchError(this.handleError(this.translateService.instant('services.reportFailed')))
              );
      } else {
          return this.http.post(`${baseUrl}/api/report/${id}`, data)
              .pipe(
                  map((data) => {
                      this.toastService.sendToast(true, this.translateService.instant('services.reportSent'))
                      return data['message']
                  }),
                  catchError(this.handleError(this.translateService.instant('services.reportFailed')))
              );
      }
  }

  /**
   * 15.12.2022
   * Get projects barcodes with workorder ID
   * @param id Workorder ID
   * @returns Array that contains list of barcodes or empty array
   * @author Jesse Lindholm
   */
  getWorkerProjects(id: number) {
    return this.http.get(`${baseUrl}/api/workerprojects/${id}`)
    .pipe(
      map((projects) => {
        return projects['message']
      }),
      catchError(this.handleError(this.translateService.instant('services.workerProjectFailed')))
    )
  }

    /**
   * Handles Http operations that failed.
   * Lets the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
     private handleError<T>(operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {

        //console.log(error)
        //console.log(`${operation} failed: ${error.message}`);

        this.toastService.sendToast(false, error.message)


        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }
}
