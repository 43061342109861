<div class="d-flex">
    <div class="align-self-center">
        <button type="button" class="btn btn-secondary" (click)="backToDashboard()">
            <img src="assets/icons/chevron_left_gray_24dp.svg">
            <span class="position-fix-back-to-teams">{{'basic.backToTeams' | translate }}</span>
        </button>
    </div>
    <load-spinner *ngIf="spinner"></load-spinner>
    <div *ngIf="!spinner" class="d-flex">
        <div class="item-border px-3 py-1 ms-4 my-2">
            <div class="my-2 d-flex align-items-center">
               <div class="small-gray-text">{{teamName}}</div>
               <div class="mx-4">
                <img class="left" src="assets/icons/groups_black_24dp.svg">
                <div class="right ms-1">{{workersCount}}</div>
               </div>
               <circle-progress
               [percent]="efficiency"
               [radius]="18"
               [outerStrokeWidth]="4"
               [innerStrokeWidth]="4"
               [space]="-4"
               [outerStrokeColor]="outerColor"
               [innerStrokeColor]="innerColor"
               [animation]="true"
               [animationDuration]="300"
               [titleFontSize]="'13'"
               [title]="efficiencyValue"
               [showUnits]=false
               [titleColor]="outerColor"
               [titleFontWeight]="'600'"
               ></circle-progress> 
               <div class="ms-1 small-gray-text">
                <div>{{'weeklyPlanningTeams.teamsOverall' | translate }}</div>
                <div>{{'basic.efficiency' | translate | lowercase }}</div>
               </div>
            </div>
        </div>
        <div class="ms-3 mb-3 pe-2" *ngIf="showSwitchCalendar">
            <load-spinner *ngIf="spinner2"></load-spinner>
            <div *ngIf="!spinner2" >
                <div ngbDropdown class="float-end ms-2">
                    <div class="small-gray-text mt-2 ms-1">{{'weeklyPlanningTeams.switchCalendar' | translate }}</div>
                    <div class="d-flex justify-content-center">
                    <button class="btn btn-secondary calendar-button" id="dropdownMenuButton" (focusout)="toggleChevron()" (click)="toggleChevron()" ngbDropdownToggle>
                        <div class="text-black calendar-text small-text py-2 float-start">{{currentCalendar}}<img class="image-chevron" [src]="chevron"></div>
                    </button>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                        <div class="max-height-calendars-dropdown">
                            <div  *ngFor="let calendar of allCalendars">
                                <button ngbDropdownItem (click)="changeCalendar(calendar.id)">{{calendar.name}}</button>
                            </div>
                        </div>
                        <div class="small-text px-2" *ngIf="allCalendars.length == 0">{{'calendar.noCalendarsFound' | translate }}</div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</div>
