
<div class="content position-relative">
    <div class="d-flex pb-2 pt-2">
        <div class="w-10">
            <img (click)="backToDashboard()" class="float-left" src="assets/icons/chevron_left_gray_24dp.svg">
        </div>
        <div class="d-flex justify-content-center w-80">
            <img src="assets/icons/add_blue_24dp.svg">
            <span *ngIf="formType=='create'" class="fw-bold">{{'fieldReporting.report.newInstallation' | translate}}</span>
            <span *ngIf="formType=='edit'" class="fw-bold">{{'fieldReporting.report.editInstallation' | translate}}</span>
        </div>
    </div>
    <div class="row content">
        <hr>
        <div class="p-4">
            <div class="fw-bold">
                <span *ngIf="customerId !== 2">{{'basic.workorder' | translate}} {{ this.id}}</span>
                <span *ngIf="customerId === 2">
                    <span *ngIf="workorderData">{{workorderData.transformer}} - {{workorderData.inverter}}<span *ngIf="workorderData.circuit"> - {{workorderData.circuit}}</span></span>
                    <span *ngIf="!workorderData">{{'basic.workorder' | translate}} {{ this.id}}</span>
                </span>
            </div>
        </div>
        <hr>
        <div class="p-4">
            <div class="fw-bold">{{'fieldReporting.report.installationDone' | translate}}</div>
            <div class="d-flex">
                <div class="col-6 ">
                    <button id="yes-button" class="btn w-90"
                            [ngClass]="clickedButtonId === 1 ? 'btn-primary' : 'btn-secondary'"
                            (click)="changeButtonId(1)"
                            [disabled]="loading">{{'basic.yes' | translate}}</button>
                </div>
                <div class="col-6">
                    <button id="no-button" class="btn w-90"
                            [ngClass]="clickedButtonId === 2 ? 'btn-danger' : 'btn-secondary'"
                            (click)="changeButtonId(2)"
                            [disabled]="loading">{{'basic.no' | translate}}</button>
                </div>
            </div>
        </div>
        <hr *ngIf="clickedButtonId === 2">
        <div *ngIf="clickedButtonId === 2" class="p-4">
            <div class="fw-bold">
                {{'basic.reasonForInterruption' | translate}}
                <span *ngIf="isMandatory">*</span>
                <span *ngIf="isOptional">{{'basic.optional' | translate}}</span>
            </div>
            <textarea type="text" rows="4" class="form-control"
                      placeholder="{{'basic.reasonForInterruptionText' | translate}}" [(ngModel)]="interruptReason"
                      [disabled]="!textareaEnabled">
            </textarea>
        </div>
        <div *ngIf="clickedButtonId === 1" class="p-4">
            <div class="fw-bold">{{'fieldReporting.report.deviation' | translate}}</div>
            <div class="d-flex">
                <div class="col-6">
                    <button id="no-button2" class="btn w-90"
                            [ngClass]="clickedButton2Id === 2 ? 'btn-primary' : 'btn-secondary'"
                            (click)="changeButton2Id(2)">{{'basic.no' | translate}}</button>
                </div>
                <div class="col-6">
                    <button id="yes-button2" class="btn w-90"
                            [ngClass]="clickedButton2Id === 1 ? 'btn-danger' : 'btn-secondary'"
                            (click)="changeButton2Id(1)">{{'basic.yes' | translate}}</button>
                </div>
            </div>
            <div class="fw-bold">{{'basic.notes' | translate}} {{'basic.optional' | translate}}</div>
            <div class="d-flex">
                <div class="col-12">
                    <textarea type="text" rows="4" class="form-control"
                              placeholder="{{'basic.notes' | translate}}" [(ngModel)]="notes">
                    </textarea>
                </div>
            </div>
        </div>
        <div *ngIf="clickedButtonId === 2 || clickedButtonId === 1" class="card-body">
            <div class="w-100" [style.opacity]="(interruptCode !== null || clickedButtonId === 1) ? '1' : '0.5'">

                <div class="w-100 d-flex cursor-pointer" (click)="imgInput.click()">
                    <img src="assets/icons/kamera_blue.svg" class="w-20 camera-icon">
                    <p class="w-70 camera-text small">
                        {{'basic.clickToTakePicture' | translate}}
                        <span *ngIf="imageMandatory">*</span>
                        <span *ngIf="imageOptional">{{'basic.optional' | translate}}</span>
                    </p>
                    <input #imgInput type="file" accept="image/*" (change)="handleImageUpload($event)"
                           style="display:none;" />
                </div>

                <!-- Display all image thumbnails -->
                <div *ngFor="let image of images">
                    <img class="thumbnail-img" [src]="image.img" height="50">
                </div>

                <!-- Warning for missing images -->
                <div *ngIf="images.length === 0 && firstTabImgCheck" class="red-text">
                    {{'fieldReporting.report.missingImage' | translate}}
                </div>

            </div>
        </div>


        <div class="d-flex justify-content-center background-light p-4 w-100">
            <button *ngIf="formType=='create'" type="button" class="btn btn-primary w-90" [disabled]="!canSave()" (click)="saveReport(formType)">
                {{'fieldReporting.wiringReport.saveReport' | translate}}
            </button>
            <button *ngIf="formType=='edit'" type="button" class="btn btn-primary w-90" [disabled]="!canSave()" (click)="saveReport(formType)">
                {{'fieldReporting.wiringReport.saveEditReport' | translate}}
            </button>
        </div>
    </div>
</div>
